import { setItem } from "@buzzeasy/shared-frontend-utilities";
import { nanoid } from "nanoid";
import { FC, createContext, useCallback, useContext, useMemo, useState } from "react";
import { IWidgetSettings } from "../models/signalR/WidgetConfiguration";
import { useGetSettingsQuery } from "../redux/apis/widgetApi";
import { getOrCreateItem } from "../utils/localStorageHelpers";
import { widgetAttributesParser } from "../utils/widgetAttributesParser";

interface IConfigContext {
  conversationId: string;
  tenantId: string;
  channelId: string;
  chatHubUrl: string;
  trackingHubUrl: string;
  standaloneMode: boolean;
  settings: IWidgetSettings;
  newConversationId(): void;
}

interface IProps {
  children?: React.ReactNode;
}

const conversationIdStorageKey = "buzzeasy_conversation_id";
const CLIENT_VERSION = "v2";
const ConfigContext = createContext<IConfigContext | undefined>(undefined);

export const useConfigContext = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error("useConfigContext can only be used in a ConfigProvider tree");
  }

  return context;
};

const ConfigProvider: FC<IProps> = ({ children }) => {
  const { url, disableHeartbeat, standaloneMode, tenantId, channelId } = useMemo(() => widgetAttributesParser(), []);
  const [conversationId, setConversationId] = useState(getOrCreateItem(conversationIdStorageKey, nanoid()));
  const chatHubUrl = `${url.href}&conversationId=${conversationId}&version=${CLIENT_VERSION}&disableHeartbeat=${disableHeartbeat}`;
  const trackingHubUrl = `${url.origin}/customer-tracking/customer_interactions?tenantId=${tenantId}&channelId=${channelId}`;

  const { data: settings } = useGetSettingsQuery(conversationId);

  const newConversationId = useCallback(() => {
    const newId = nanoid();
    setItem(conversationIdStorageKey, newId);
    setConversationId(newId);
  }, [setConversationId]);

  const contextValue = useMemo(
    () => {
      if (!settings)
        return null;

      return {
        tenantId,
        conversationId,
        channelId,
        chatHubUrl,
        trackingHubUrl,
        standaloneMode,
        settings,
        newConversationId,
      };
    },
    [channelId, chatHubUrl, conversationId, newConversationId, settings, standaloneMode, tenantId, trackingHubUrl],
  );

  if (!contextValue) {
    return <></>;
  }

  return (
    <ConfigContext.Provider
      value={contextValue}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
